import React from "react";
import { Link } from "gatsby";
import Container from "./container";
import { cn } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import { getBensenEverydayUrl } from "../lib/helpers";
import PortableText from "./portableText";
import Pagination from "./pagination";

import * as styles from "../styles/components/updates-list.module.css";

const BensenEverydayList = (props) => {

  const {
    posts,
    currentPage,
    numPages
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <div className="grid">
          {posts.map(({node: post}) => (
            <div key={post.id} className={cn("col-12 col-md-6", styles.item)}>
              {post.featuredImage && post.featuredImage.asset && (
                <div className={styles.image}>
                  <Link to={getBensenEverydayUrl(post.slug)}>
                    <img
                      src={imageUrlFor(buildImageObj(post.featuredImage))
                        .auto("format")
                        .url()}
                      alt={post.featuredImage.alt}
                    />
                  </Link>
                </div>
              )}
              <div className={styles.meta}>
                {post.title && (
                  <h2 className={styles.title}><Link to={getBensenEverydayUrl(post.slug)}>{post.title}</Link></h2>
                )}
                {post.description && (
                  <div className={styles.excerpt}>
                    <p>{post.description}</p>
                  </div>
                )}
                <Link to={getBensenEverydayUrl(post.slug)} className="view--more">View</Link>
              </div>
            </div>
          ))}
        </div>
        {numPages > 1 && (<Pagination base="/bensen-everyday" currentPage={currentPage} numPages={numPages} />)}
      </Container>
    </div>
  );
}

export default BensenEverydayList;
