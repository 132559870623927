import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";

import PageHeader from "../components/page-header";
import BensenEverydayList from "../components/bensen-everyday-list";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query BensenEverydayPageQuery($skip: Int!, $limit: Int!) {
    bensenEverydayPage: sanityBensenEverydayLanding {
      title
      introCopy
      featuredImage {
        ...SanityImage
        alt
      }
    }

    bensenEveryday: allSanityBensenEveryday(
      sort: { fields: [orderRank], order: ASC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          description
          featuredImage {
            ...SanityImage
            alt
          }
        }
      }
    }
  }
`;

const BensenEverydayPageTemplate = (props) => {
  const { data, pageContext, errors } = props;
  const { currentPage, numPages } = pageContext;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const bensenEverydayPage = (data || {}).bensenEverydayPage;
  const bensenEveryday = (data || {}).bensenEveryday.edges;

  return (
    <Layout>
      <Seo
        title={bensenEverydayPage.title}
        description={bensenEverydayPage.introCopy}
        image={bensenEverydayPage.featuredImage}
      />

      {bensenEverydayPage.title && (<PageHeader title={bensenEverydayPage.title} description={bensenEverydayPage.introCopy} />)}
      {bensenEveryday && (<BensenEverydayList posts={bensenEveryday} currentPage={currentPage} numPages={numPages} />)}
    </Layout>
  );
};

export default BensenEverydayPageTemplate;
