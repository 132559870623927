import React from "react";
import { Link } from "gatsby";

import * as styles from "../styles/components/pagination.module.css";

const Pagination = (props) => {

  const {
    base,
    currentPage,
    numPages
  } = props;

  // A sweet helper function to create pagination object
  const createPaginationObjects = (length, page, increment = 2) => 
    Array.from({ length }, (_, i) => ({
      link: base + `/page/${i + increment}`,
      index: i + increment,
      current: page === i + increment,
    }));
  
  // Create the navigation link
  let navItems = [
    {
      link: base,
      index: 1,
      current: currentPage === 1,
    },
  ];
  if (numPages <= 5) {
    navItems = [
      ...navItems,
      ...Array.from({ length: numPages - 1 }, (_, i) => ({
        link: base + `/page/${i + 2}`,
        index: i + 2,
        current: currentPage === i + 2,
      })),
    ];
  } else {
    // We have a situation where we have to show the first
    // item, three items around the current one
    // and also the last item
    if (currentPage <= 3) {
      // If the current one is closer to the start
      navItems = [
        ...navItems,
        ...createPaginationObjects(3, currentPage),
        {
          separator: true,
          index: 'starter-separator',
        },
        {
          link: base + `/page/${numPages}`,
          index: numPages,
          current: false,
        },
      ];
    } else if (currentPage > numPages - 3) {
      // If the current one is closer to the last one
      navItems = [
        ...navItems,
        {
          separator: true,
          index: 'finisher-separator',
        },
        ...createPaginationObjects(4, currentPage, numPages - 3),
      ];
    } else {
      navItems = [
        ...navItems,
        {
          separator: true,
          index: 'starter-separator',
        },
        ...createPaginationObjects(3, currentPage, currentPage - 1),
        {
          separator: true,
          index: 'finisher-separator',
        },
        {
          link: base + `/page/${numPages}`,
          index: numPages,
          current: false,
        },
      ];
    }
  }

  return (
    <div className={styles.root}>
      {navItems.map(item => (
        <>
          {item.separator ? (
            <span key={item.index}>...</span>
          ) : (
            <Link to={item.link} aria-label={`Go to page ${item.index}`} key={item.index}>{item.index}</Link>
          )}
        </>
      ))}
    </div>
  )
}

export default Pagination;